import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import {
    recoveryPasswordAlertState,
    recoveryPasswordInfo,
} from "../../../../reduxStore/slices/AccountChangeAlertSlice";

import logo from "../../../../pageComponents/icons/svg/Logo_SB_Full.svg";
import Header from "../../../../pageComponents/headerFooter/Header";
import Footer from "../../../../pageComponents/headerFooter/Footer";
import Loader from "../../../../loader/Loader";

const RecoveryPasswordToken = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(null);


    const [response, setResponse] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            token: "",
        },
    });

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true)
        try {
            await axios.get(`${process.env.REACT_APP_SERVER_DOMAIN}/reset-password/${data.token}`)
                .then((res) => {
                    setResponse(res.data.message);
                    setTimeout(() => {
                        setResponse(null);
                        dispatch(recoveryPasswordInfo(res.data.message));
                        dispatch(recoveryPasswordAlertState(true));
                        navigate("/login");
                    }, 3000);
                })
                .catch(({response}) => {
                    setErrorResponse(response.data.error);
                    setTimeout(() => {
                        setErrorResponse(null);
                    }, 3000);
                });
        } catch (e) {
            console.error(e);
        }
        setLoading(false)
    });

    return !isLoading ? (
        <div className="login-box">
            <Header titleName="Recover Your Password"/>
            <form autoComplete="off" onSubmit={onSubmit}>
                <div>
                    <img
                        src={logo ? logo : "logo"}
                        alt="logo"
                        style={{width: 130, height: 130, marginBottom: 30}}
                    />
                    <h2>Enter the code sent to you by email</h2>
                </div>
                <div className="user-box">
                    <input{...register("token", {
                        required: "Token is required",
                        pattern: {
                            value: /^.+$/,
                            message: "Invalid email address",
                        },
                    })}
                          name="token" type="text" required/>
                    <label>Confirmation code</label>
                    {response && <p style={{color: "green"}}>{response}</p>}
                    {errorResponse && <p style={{color: "red"}}>{errorResponse}</p>}
                </div>

                <button className="submit-btn" type="submit" disabled={!isValid}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    Enter
                </button>
                <div className="recovery-token-link">
                    <a href="/login">Back to login.</a>
                </div>
            </form>
            <Footer/>
        </div>
    ) : (
        <Loader/>
    );
};

export default RecoveryPasswordToken;
