import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "universal-cookie";
import { addUser, setLogin } from "../reduxStore/slices/GetUserSlice";

const cookies = new Cookies();

export const useHandleLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        // Set loading state if required
        // setLoading(true);

        // Clear cache in localStorage
        localStorage.removeItem("paymentHistory");
        localStorage.removeItem("paymentHistoryTimestamp");
        localStorage.removeItem("ordersHistory");
        localStorage.removeItem("OrdersHistoryTimestamp");

        // Remove cookies and update redux state
        cookies.remove("usat", { path: "/" });
        cookies.remove("sbVersion", { path: "/" });
        dispatch(addUser(null));
        dispatch(setLogin(false));

        // Navigate to the home page or login page
        navigate(`/`);

        // Reset loading state if required
        // setLoading(false);
    };

    return handleLogout;
};
