import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
// import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {Link, useNavigate} from "react-router-dom";
import "./PaymentCancel.css";
import {useSelector} from "react-redux";
import Cookies from "universal-cookie";

const PaymentCancel = () => {
    const [successResponse, setSuccessResponse] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);
    const requestMade = useRef(false);
    const user = useSelector((state) => state.user.user);
    const navigate = useNavigate();
    const cookies = new Cookies();
    const handleNavigate = () => {
        // Navigate to the user's settings page
        navigate(user ? `/cabinet/${user.username}/home` : '/login');
        window.location.reload();
    };

    useEffect(() => {
        if (requestMade.current) return; // Prevent duplicate execution
        requestMade.current = true;

        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");
        const rType = searchParams.get("rType");

        const subscriptionId = searchParams.get("subscription_id");
        const baToken = searchParams.get("ba_token");

        if (!rType) {
            navigate(user ? `/cabinet/${user.username}/home` : '/login');
            window.location.reload();
        }

        if (cookies.get("usat")) {
            try {
                const usat = document.cookie
                    .split('; ')
                    .find(row => row.startsWith('usat='))
                    ?.split('=')[1];

                switch (rType) {
                    case 'user_order':
                        axios
                            .get(`${process.env.REACT_APP_SERVER_DOMAIN}/payment-cancel?rType=${rType}&token=${token}`, {
                                headers:
                                    {
                                        Authorization: `${usat}`, // Use the token in the Authorization header
                                    },
                                withCredentials: true
                            })
                            .then((res) => {
                                setSuccessResponse(res.data);
                            })
                            .catch(({response}) => {
                                setErrorResponse(response.data);
                            });
                        break;
                    case 'user_subscription':
                        axios
                            .get(`${process.env.REACT_APP_SERVER_DOMAIN}/payment-cancel?rType=${rType}&subscription_id=${subscriptionId}&ba_token=${baToken}&token=${token}`, {
                                headers:
                                    {
                                        Authorization: `${usat}`, // Use the token in the Authorization header
                                    },
                                withCredentials: true
                            })
                            .then((res) => {
                                setSuccessResponse(res.data);
                            })
                            .catch(({response}) => {
                                setErrorResponse(response.data);
                            });
                        break;
                    default:
                        navigate(user ? `/cabinet/${user.username}/home` : '/login');
                        window.location.reload();
                        break;
                }

            } catch (e) {
                console.error("Unexpected error:", e);
            }
        }

        navigate(user ? `/cabinet/${user.username}/home` : '/login');
        window.location.reload();

    }, []);

    return (
        <div className="cancel-wrapper">
            {successResponse && (
                <p style={{color: "rgb(36,203,253)"}}>{successResponse}</p>
            )}
            {errorResponse && <p style={{color: "red"}}>{errorResponse}</p>}
            <div onClick={handleNavigate} className="return-button" style={{transition: ".3s"}}>
                Return to personal office
            </div>

        </div>
    );
};

export default PaymentCancel;
