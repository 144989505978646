import React, {useEffect, useState} from "react";
import "./HomePage.css";
import "../pageComponents/headerFooter/HeaderSection.css";
import ProgressSection from "./progressSection/ProgressSection";
import OrderBtn from "./orderBtn/OrderBtn";
import Pricing from "./pricing/Pricing";
import logoSvg from '../pageComponents/icons/svg/Logo_SB_Full.svg'
import Header from "../pageComponents/headerFooter/Header";
import Footer from "../pageComponents/headerFooter/Footer";
import ContactForm from "./contactForm/ContactForm";
import {useLocation, useNavigate} from "react-router-dom";
import EquipmentSection from "./equipmentSection/EquipmentSection";
import CustomPricing from "./customPricing/CustomPricing";
import {useDispatch, useSelector} from "react-redux";

const HomePage = () => {

    const { hash } = useLocation();
    const [isHovered, setIsHovered] = useState(false);
    const user = useSelector((state) => state.user.user); // Check if the user is logged in
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    const handleNavigateCabinet = () => {
        // Clear cookies or session tokens if necessary
        navigate(`/cabinet/${user.username}/home`);
    };
    const handleNavigateLogin = () => {
        // Clear cookies or session tokens if necessary
        navigate(`/login`);
    };

    return (
        <div className="homepage">
            <Header titleName="Smart Bird"/>
            <section id="about-us" className="about-us">
                <div className="about-content">
                    <div
                        className="logo-container"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {user ? (
                            <>
                                <a
                                    onClick={handleNavigateCabinet}
                                    className="button-link"
                                    role="button"
                                    href="#"
                                >
                                    <img src={logoSvg} alt="Logo" className="logo" style={{ width: '250px', height: 'auto' }} />
                                </a>
                                <div className="login-popup" onClick={handleNavigateCabinet}>
                                    <div className="login-title">Enter</div>
                                    <div className="login-description">To your cabinet</div>
                                </div>
                            </>
                        ) : (
                            <>
                                <a href="/login" >
                                    <img src={logoSvg} alt="Logo" className="logo" style={{ width: '250px', height: 'auto' }} />
                                </a>
                                <div className="login-popup" onClick={handleNavigateLogin}>
                                    <div className="login-title">Login</div>
                                    <div className="login-description">To your account</div>
                                </div>
                            </>
                        )}
                    </div>
                    <h2>About Smart Home Solution</h2>
                    <p>At Smart Bird Solution, we believe in making homes smarter, more convenient, and fully connected. Whether you’re just starting with smart devices or looking to integrate a full home system, we are here to help. Our custom smart home solutions are tailored to your needs, allowing you to control and monitor your home with ease. From lighting and security to entertainment systems and more, we ensure everything works seamlessly together. Plus, our flexible setups let you modify configurations, and our ongoing support ensures you’re always connected and in control. Welcome to the future of smart living!</p>
                </div>
            </section>

            <section id="what-we-do" className="what-we-do">
                <div className="services-intro">
                    <h2>Installation and configuration</h2>
                    <p>Steps to meet smart bird.</p>
                </div>
                <ProgressSection />
                <OrderBtn />
            </section>

            <section>
                <EquipmentSection/>
                {/* Tiles Section */}
                <h2>Full Packages.</h2>
                <div className="tiles-section">
                    <Pricing/>
                </div>
                <h2>Custom Options.</h2>
                <div className="tiles-section">
                    <CustomPricing/>
                </div>
            </section>
            <section id="order-now" className="order-now">
                <div className="order-content">
                    <h2 className="service-header">Ready to Transform Your Home?</h2>
                    <p className="service-header">Review Smart Home Services Page.</p>
                    <a href="/services" className="cta-button">Review</a>
                </div>
            </section>

            <ContactForm/>

            <Footer/>

        </div>
    );
};

export default HomePage;
