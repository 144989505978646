import React, {useState, useEffect} from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../loader/Loader";
import "./UserOrders.css";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";

const ORDERS_PER_PAGE = 5;

const UserOrders = () => {
    const user = useSelector((state) => state.user.user);
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPageOrders, setCurrentPageOrders] = useState(1);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const cachedDataOrders = JSON.parse(localStorage.getItem("ordersHistory"));
                const cacheTimestampOrders = localStorage.getItem("OrdersHistoryTimestamp");
                const now = new Date().getTime();

                if (cachedDataOrders && cacheTimestampOrders && now - cacheTimestampOrders < 10 * 60 * 1000) {
                    setOrders(cachedDataOrders);
                    return;
                }

                const usat = document.cookie
                    .split("; ")
                    .find((row) => row.startsWith("usat="))
                    ?.split("=")[1];
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_DOMAIN}/user-order-check`,
                    {
                        userId: user?.id || "Id Not set",
                        username: user?.username || "User Not set",
                        userEmail: user?.email || "Email Not set",
                    },
                    {
                        headers: {
                            Authorization: `${usat}`,
                        },
                        withCredentials: true,
                    }
                );
                const orderData = response.data.data || [];
                setOrders(orderData);
                localStorage.setItem("ordersHistory", JSON.stringify(orderData));
                localStorage.setItem("OrdersHistoryTimestamp", new Date().getTime());
            } catch (error) {
                console.error("Error fetching orders:", error);
                setOrders([]);
            }
        };

        if (user?.id) {
            fetchOrders();
        }
    }, [user]);

    const totalOrderPages = Math.ceil(orders.length / ORDERS_PER_PAGE);
    const currentOrders = orders.slice(
        (currentPageOrders - 1) * ORDERS_PER_PAGE,
        currentPageOrders * ORDERS_PER_PAGE
    );

    const handleOrderPageChange = (page) => setCurrentPageOrders(page);

    const logoutToLoginPage = () => {
        dispatch(addUser(null));
        dispatch(setLogin(false));
        cookies.remove("usat");
        cookies.remove("sbVersion");
        navigate(`/login`);
    }

    const handlePay = async (order) => {
        setLoading(true);
        const subscriptionData = {
            userEmail: order.userEmail,
            orderNumber: order.orderNumber,
        };
        if (cookies.get("usat")) {
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            try {
                const res = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/order-payment`,
                    subscriptionData,
                    {
                        headers:
                            {
                                Authorization: `${usat}`, // Use the token in the Authorization header
                            },
                        withCredentials: true
                    },
                    {timeout: 5000});
                if (res.status === 200) {
                    console.log("processed");
                    window.location.href = res.data;
                } else {
                    console.log("Unexpected status:", res.status);
                    window.location.href = res.data;
                    alert("Unexpected error! Operation wasn't successful. Please contact us.");
                    logoutToLoginPage();
                }

            } catch (e) {
                if (e.response) {
                    // Server responded with a status outside the 2xx range
                    const {status} = e.response;

                    if (status === 401) {
                        console.log("Authorization error");
                        logoutToLoginPage();
                    } else if (status === 404) {
                        console.log("User not found");
                        logoutToLoginPage();
                    } else if (status === 500) {
                        console.log("Failed to create payment");
                    } else {
                        console.log("Unhandled error:", status);
                    }
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                } else {
                    // Network or unexpected error
                    console.error("Unexpected error:", e.message || e);
                    alert("Unexpected error! Operation wasn't successful. Please try again.");
                }
            }
        }

    };

    return loading ? (
        <Loader/>
    ) : (
        <section className="user-billings-content">
            <h2>Order Details</h2>
            {orders.length === 0 ? (
                <div className="no-statements-message">
                    <p>No orders available yet.</p>
                </div>
            ) : (
                <>
                    <table className="order-details-table">
                        <thead>
                        <tr>
                            <th>Order Number</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentOrders.map((order, index) => (
                            <tr key={index}>
                                <td>{order.orderNumber}</td>
                                <td>{order.orderStatus}</td>
                                <td>{order.orderType}</td>
                                <td>{order.orderDescription}</td>
                                <td>${order.orderPrice}</td>
                                <td>
                                    <button className="pay-button"
                                            onClick={() => handlePay(order)}>Pay
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {[...Array(totalOrderPages)].map((_, index) => (
                            <button
                                key={index}
                                className={currentPageOrders === index + 1 ? "active-page" : ""}
                                onClick={() => handleOrderPageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}

        </section>
    );
};

export default UserOrders;
