import { createSlice } from "@reduxjs/toolkit";

const GetUserSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isLoggedIn: false,
  },

  reducers: {
    addUser(state, action) {
      state.user = action.payload;
    },
    setLogin(state, action) {
      state.isLoggedIn = action.payload;
    },
  },
});

export const { addUser, setLogin } = GetUserSlice.actions;
export default GetUserSlice.reducer;
