// import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./components/appRoutes/AppRoutes";
import "./App.css";
import {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import {useDispatch} from "react-redux";
import {addUser} from "./components/reduxStore/slices/GetUserSlice";
import jwtDecode from "jwt-decode";

function App() {
    // const [firstName, setFirstName] = useState("John");
    const [isLogin, setIsLogin] = useState(null);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    useEffect(() => {
        try {
            // if (isLogin) {
            const usatCookie = cookies.get("usat");
            if(!usatCookie){
            }else{
                dispatch(addUser(jwtDecode(usatCookie)));
                // window.location.reload(); // todo to be optimised later
            }
            // }
        } catch (e) {
            console.error(e);
        }
    }, [isLogin]);

    return (
        <div className="App">
            <BrowserRouter>
                <AppRoutes />
            </BrowserRouter>
        </div>
    );
}

export default App;
