import React, { useState, useEffect } from "react";
import "./UserBillings.css";
import "../../../pageComponents/headerFooter/HeaderSection.css";
import Header from "../../../pageComponents/headerFooter/Header";
import Footer from "../../../pageComponents/headerFooter/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { addUser, setLogin } from "../../../reduxStore/slices/GetUserSlice";
import UserOrders from "./UserOrders";
import UserStatements from "./UserStatements";

const UserBillings = () => {
    const { hash } = useLocation();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);

    const [activeTab, setActiveTab] = useState("user-orders");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [hash]);

    useEffect(() => {
        try {
            setLoading(true);
            const usatCookie = cookies.get("usat");
            if (!usatCookie) {
                navigate(`/login`);
            }
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
        setLoading(false);
    }, []);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return loading ? (
        <Loader />
    ) : (
        <div className="user-billings-page">
            <Header titleName="User Billings" />
            <section className="user-billings-content">
                <div className="tab-buttons">
                    <button
                        className={`order-btn ${activeTab === "user-orders" ? "active" : ""}`}
                        onClick={() => handleTabChange("user-orders")}
                    >
                        User Orders
                    </button>
                    <button
                        className={`order-btn ${activeTab === "user-statements" ? "active" : ""}`}
                        onClick={() => handleTabChange("user-statements")}
                    >
                        User Statements
                    </button>
                </div>

                <div className="billings-details">
                    {activeTab === "user-orders" && <UserOrders />}
                    {activeTab === "user-statements" && <UserStatements />}
                </div>
                <button
                    className="back-cabinet-btn"
                    onClick={() => navigate(`/cabinet/${user?.username}/home`)}
                >
                    Back to Cabinet
                </button>
            </section>
            <Footer />
        </div>
    );
};

export default UserBillings;
