import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accountChangesState: false,
  accountChangesInfo: null,
  recoveryPasswordState: false,
  recoveryPasswordInfo: null,
  passwordSettingsState: false,
  passwordSettingsInfo: null,
};

const AccountChangesAlertSlice = createSlice({
  name: "accountChangesAlert",
  initialState,
  reducers: {
    accountChangesAlertState(state, action) {
      state.accountChangesState = action.payload;
    },

    accountChangesInfoAlertState(state, action) {
      state.accountChangesInfo = action.payload;
    },

    recoveryPasswordAlertState(state, action) {
      state.recoveryPasswordState = action.payload;
    },

    recoveryPasswordInfo(state, action) {
      state.recoveryPasswordInfo = action.payload;
    },

    passwordSettingsState(state, action) {
      state.passwordSettingsState = action.payload;
    },

    passwordSettingsInfo(state, action) {
      state.passwordSettingsInfo = action.payload;
    },
  },
});

export const {
  accountChangesAlertState,
  accountChangesInfoAlertState,
  recoveryPasswordAlertState,
  recoveryPasswordInfo,
  passwordSettingsState,
  passwordSettingsInfo,
} = AccountChangesAlertSlice.actions;

export default AccountChangesAlertSlice.reducer;
