import React, { useState, useEffect } from "react";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import Loader from "../../../loader/Loader";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";
import {useLocation, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import "./UserStatements.css";

const PURCHASES_PER_PAGE = 24;

const UserStatements = () => {
    const user = useSelector((state) => state.user.user);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPagePurchases, setCurrentPagePurchases] = useState(1);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPaymentHistory = async () => {
            setLoading(true);

            try {
                const cachedData = JSON.parse(localStorage.getItem("paymentHistory"));
                const cacheTimestamp = localStorage.getItem("paymentHistoryTimestamp");
                const now = new Date().getTime();

                if (cachedData && cacheTimestamp && now - cacheTimestamp < 10 * 60 * 1000) {
                    setPaymentHistory(cachedData);
                    setLoading(false);
                    return;
                }

                if (cookies.get("usat")) {
                    const usat = document.cookie
                        .split("; ")
                        .find((row) => row.startsWith("usat="))
                        ?.split("=")[1];
                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER_DOMAIN}/payment-history`,
                        {
                            userId: user?.id || "Id Not set",
                            username: user?.username || "User Not set",
                            userEmail: user?.email || "Email Not set",
                        },
                        {
                            headers: {
                                Authorization: `${usat}`,
                            },
                            withCredentials: true,
                        }
                    );

                    const paymentHistoryData = response.data.data;
                    if (Array.isArray(paymentHistoryData)) {
                        setPaymentHistory(paymentHistoryData);
                        localStorage.setItem("paymentHistory", JSON.stringify(paymentHistoryData));
                        localStorage.setItem("paymentHistoryTimestamp", new Date().getTime());
                    } else {
                        console.error("Unexpected data format:", paymentHistoryData);
                        setPaymentHistory([]);
                    }
                } else {
                    dispatch(addUser(null));
                    dispatch(setLogin(false));
                    cookies.remove("usat");
                    cookies.remove("sbVersion");
                    navigate(`/login`);
                }
            } catch (error) {
                console.error("Error fetching payment history:", error);
                setError("Error fetching payment history");
                setPaymentHistory([]);
            } finally {
                setLoading(false);
            }
        };

        if (user?.id) {
            fetchPaymentHistory();
        }

        const intervalId = setInterval(() => {
            fetchPaymentHistory();
        }, 10 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [user]);

    const totalPurchasePages = Math.ceil(paymentHistory.length / PURCHASES_PER_PAGE);
    const currentPurchases = paymentHistory.slice(
        (currentPagePurchases - 1) * PURCHASES_PER_PAGE,
        currentPagePurchases * PURCHASES_PER_PAGE
    );

    const handlePurchasePageChange = (page) => setCurrentPagePurchases(page);

    return loading ? (
        <Loader />
    ) : (
        <section className="user-billings-content">
            <h2>Purchase History</h2>
            {paymentHistory.length === 0 ? (
                <div className="no-statements-message">
                    <p>No purchase statements available yet.</p>
                </div>
            ) : (
                <>
                    <table className="payment-history-table">
                        <thead>
                        <tr>
                            <th>Purchase Type</th>
                            <th>Detais</th>
                            <th>Amount</th>
                            <th>Date</th>
                            <th>Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentPurchases.map((payment, index) => (
                            <tr key={index}>
                                <td data-label="Purchase Type">{payment.purchaseType}</td>
                                <td data-label="Details">{payment.paymentDescription}</td>
                                <td data-label="Amount">${payment.amount}</td>
                                <td data-label="Date">
                                    {new Date(payment.purchaseDate).toLocaleDateString()}
                                </td>
                                <td data-label="Time">
                                    {new Date(payment.purchaseDate).toLocaleTimeString()}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        {[...Array(totalPurchasePages)].map((_, index) => (
                            <button
                                key={index}
                                className={
                                    currentPagePurchases === index + 1 ? "active-page" : ""
                                }
                                onClick={() => handlePurchasePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </>
            )}
        </section>
    );
};

export default UserStatements;
