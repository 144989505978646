import * as yup from "yup";

const phoneRegExp =
    /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/;

export const YupUserSettings = yup.object({
  phone: yup
    .string()
    .test("Phone", "The input contains whitespace", (value) => {
      return value === "" || !/\s/.test(value);
    }).test("Validate", "The values not entered correctly", value => {
        return value === "" || phoneRegExp.test(value)
      })
    .trim(),
  userName: yup
    .string()
    .max(15, "No more than 15 characters")
    .test(
      "no-specialCharacters",
      "The input contains special characters",
      (value) => {
        return !/[@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
      }
    )
    .test("no-spaces", "The input contains whitespace", (value) => {
      return value === "" || /^\S+$/.test(value);
    })
    .test("User-name", "The values not entered correctly", (value) => {
      return value === "" || /^[a-zA-Z0-9]+$/u.test(value);
    })
    .trim(),
  fullUserName: yup
    .string()
    .test("Full-name", "The values not entered correctly", (value) => {
      return value === "" || /^[a-zA-Z]+(\s[a-zA-Z]+){1,2}$/u.test(value);
    })
    .trim(),
});
