import React from 'react';
import './SupportPage.css';
import Header from "../pageComponents/headerFooter/Header";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";
import Footer from "../pageComponents/headerFooter/Footer";

const SupportPage = () => {
    const tiers = ["Bonus", "Bronze", "Silver", "Gold"];
    const features = [
        { feature: "Access to Premium Dashboard.", access: [true, true, true, true] },
        { feature: "Laundry timer.", access: [true, true, true, true] },
        { feature: "Resident system.", access: [true, true, true, true] },
        { feature: "Add new devices for free (if supported by the system)", access: [false, false, true, true] },
        { feature: "Local Service committee within 50 miles for free", access: [false, false, true, true] },
        { feature: "Enjoy a 15% discount on home upgrades", access: [false, false, false, true] },
        { feature: "Relocation services are discounted by 50%", access: [false, false, false, true] },
        { feature: "Relocation services are free for users who have held premium status", access: [false, false, false, true] },

    ];


    return (
        <div className="support-page">
            <Header titleName="Smart Bird Solution Support" />
            <section className="support-section">
                <h3 className="support-title">Smart Bird Support Overview</h3>
                <div className="support-content">
                    <h4>General Support</h4>
                    <ul>
                        <li>Receive email support responses within 48 hours.</li>
                        <li>Home service support is available at a specific hourly rate.</li>
                        <li>Charges apply for adding new device(s).</li>
                        <li>Relocation of Home Assistant systems incurs additional costs.</li>
                        <li>Service committee expenses may apply.</li>
                        <li>Any software bugs fix for free.</li>
                        <li>New phone\user connection supported for free.</li>
                    </ul>

                    <h4>Premium User Support</h4>
                    <p><strong>Premium users</strong> are defined as:</p>
                    <ul>
                        <li>Customers who joined SB within the last 3 months
                            <ul style={{ listStyleType: 'circle' }}>
                            <li>Bonus first 3 months unless subscription purchased.</li>
                            </ul>
                        </li>
                        <li>or customers who have maintained SB Premium status with the following rules without any subscription cancellations or missed payments.
                            <ul style={{ listStyleType: 'circle' }}>
                            <li>Bronze 0-3 months of active subscription.</li>
                            <li>Silver 3-6 months of active subscription.</li>
                            <li>Gold 6 months or more of active subscription.</li>
                            </ul>
                        </li>
                    </ul>

                    <h4>Support Comparison by Tier</h4>
                    <table className="support-table">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            {tiers.map((tier, index) => (
                                <th key={index}>{tier}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {features.map((item, index) => (
                            <tr key={index}>
                                <td>{item.feature}</td>
                                {item.access.map((hasAccess, tierIndex) => (
                                    <td key={tierIndex}>{hasAccess ? "✅" : "❌"}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>


                    <p>
                        <strong>Note:</strong> Contact us to verify your eligibility for these discounts.
                        For more information, visit our
                        <a href="/services"> Services Page</a>.
                    </p>

                </div>
            </section>
            <BackHomeBtn />
            <Footer />
        </div>
    );
};

export default SupportPage;
