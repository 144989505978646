import React, {useState} from "react";
import {Button, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import axios from "axios";
import {yupResolver} from "@hookform/resolvers/yup";
import {YupPasswordSettings} from "./yupUserPassword/YupUserPassword"; // Assuming this is your validation schema
import "./PasswordSettings.css";
import {useNavigate} from "react-router-dom";

const PasswordSettings = () => {
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errorResponse, setErrorResponse] = useState();
    const [response, setResponse] = useState();

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(YupPasswordSettings),  // Use Yup for validation
        defaultValues: {
            oldPassword: "",
            password: "",
            confirmPassword: "",
        },
    });

    const onSubmit = handleSubmit(async (data) => {
        try {
            setResponse(null);
            setErrorResponse(null);
            const usat = await document.cookie
                .split('; ')
                .find(row => row.startsWith('usat='))
                ?.split('=')[1];
            await axios.post(
                `${process.env.REACT_APP_SERVER_DOMAIN}/uppas`,
                {
                    userId: user.id,
                    password: data.oldPassword,
                    newPassword: data.confirmPassword,
                },
                {
                    headers:
                        {
                            Authorization: `${usat}`, // Use the token in the Authorization header
                        },
                    withCredentials: true, // Include cookies
                }
            ).then((res) => {
                let response = res
                if (res.status === 200){
                    setResponse(response.data.message);
                    setTimeout(() => {
                        reset();
                    }, 15000);
                }else{
                    setErrorResponse(response.data.error);
                }
            }).catch((e) => {
                setErrorResponse(e.response.data.error);
                // navigate('/login');

            });


        } catch (error) {
            setErrorResponse(error.response?.data?.error || "An error occurred");
            setTimeout(() => {
                setErrorResponse(null);
            }, 15000);
        }
    });

    return (
        <div className="password-settings-container">
            <form onSubmit={handleSubmit(onSubmit)} className="password-settings-form">
                <h3 className="password-settings-title">Password Settings</h3>

                <div className="password-input-group">
                    <TextField
                        {...register("oldPassword")}
                        type="password"
                        label="Current Password"
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.oldPassword}
                        helperText={errors.oldPassword ? errors.oldPassword.message : ""}
                    />
                </div>

                <div className="password-input-group">
                    <TextField
                        {...register("password")}
                        type="password"
                        label="New Password"
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ""}
                    />
                </div>

                <div className="password-input-group">
                    <TextField
                        {...register("confirmPassword")}
                        type="password"
                        label="Confirm New Password"
                        variant="outlined"
                        fullWidth
                        required
                        error={!!errors.confirmPassword}
                        helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
                    />
                </div>

                <button type="submit" className="password-update-btn">
                    Update Password
                </button>

                {response && (
                    <div className="password-success-message">{response}</div>
                )}

                {errorResponse && (
                    <div className="password-error-message">{errorResponse}</div>
                )}
            </form>
        </div>
    );
};

export default PasswordSettings;
