import React, {useState} from "react";
import {useSelector} from "react-redux";
import axios from "axios";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import logo from "../../../../pageComponents/icons/svg/Logo_SB_Full.svg";
import Header from "../../../../pageComponents/headerFooter/Header";
import Footer from "../../../../pageComponents/headerFooter/Footer";
import Loader from "../../../../loader/Loader";

const ForgotPassword = () => {
    const [response, setResponse] = useState(null);
    const [errorResponse, setErrorResponse] = useState(null);
    const [isLoading, setLoading] = useState(null);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
        setError,
        clearErrors,
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            userEmail: "",
        },
    });

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        setErrorResponse(null);
        setResponse(null);
        try {
            // Send request to the server
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_DOMAIN}/forgot-password`,
                data,
                {timeout: 5000});
            if (response.status === 200) {
                // On success, set the success message and navigate to the login page
                setResponse("Email was sent, please check your inbox or spam");
                setTimeout(() => {
                    navigate("/recovery-password"); // Navigate to recovery-password page after success
                }, 2000); // You can set a delay before navigation if needed
            } else {
                setErrorResponse("Email not found. Please try again.");
            }
        } catch (e) {
            // On failure, show the error message
            if (e.response && e.response.data) {
                if (e.response.status === 404) {
                    setErrorResponse("Email not found. Please try again.");
                } else {
                    setErrorResponse("Error, please try again.");
                }
            } else {
                setErrorResponse("An error occurred. Please try again.");
            }
            console.error(e);
        }
        setLoading(false);
    });

    return !isLoading ? (

        <div className="login-box">
            <Header titleName="Forget Password"/>
            <form autoComplete="off" onSubmit={onSubmit}>
                <div>
                    <img
                        src={logo ? logo : "logo"}
                        alt="logo"
                        style={{width: 130, height: 130, marginBottom: 30}}
                    />
                    <h2>Enter your email to reset password</h2>
                </div>
                <div className="user-box">
                    <input
                        {...register("userEmail", {
                            required: "Email is required",
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: "Invalid email address",
                            },
                        })}
                        name="userEmail"
                        type="text"
                        required
                    />
                    <label>Your email</label>
                    {/* Display error message for invalid email */}
                    {errors.userEmail && <p style={{color: "red"}}>{errors.userEmail.message}</p>}
                    {response && <p style={{color: "green"}}>{response}</p>}
                    {errorResponse && <p style={{color: "red"}}>{errorResponse}</p>}
                </div>

                {/* Button is disabled if the form is invalid */}
                <button className="submit-btn" type="submit" disabled={!isValid}>
                    <span/>
                    <span/>
                    <span/>
                    <span/>
                    Send
                </button>
                <div className="recovery-token-link">
                    <a href="/recovery-password">Recover token form your email.</a>
                </div>
                <div className="recovery-token-link">
                    <a href="/login">Back to login.</a>
                </div>
            </form>
            <Footer/>
        </div>
    ) : (
        <Loader/>
    );
};

export default ForgotPassword;
