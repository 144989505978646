import * as yup from "yup";

export const YupPasswordSettings = yup.object({
  password: yup
    .string()
    .required("This field cannot be empty")
    .min(6, "Password length should be at least 6 characters")
    .matches(/(?=.*[0-9])/, "String contains at least one number")
    .matches(
      /(?=.*[!@#$%^&*])/,
      "String contains at least one special character"
    )
    .matches(
      /(?=.*[A-Z])/,
      "String contains at least one uppercase Latin letter"
    )
    .matches(/^\S+$/, "The password must not contain spaces"),
  confirmPassword: yup
    .string()
    .required("This field cannot be empty")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});
