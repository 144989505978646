import React from "react";
import "./ServicesPage.css";
import Footer from "../pageComponents/headerFooter/Footer";
import Header from "../pageComponents/headerFooter/Header";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";

const tiers = ["Studio", "1-2 Bedroom", "3-5 Bedroom", "5+ Bedroom"];
const features = [
    { feature: "System installation", access: [true, true, true, true] },
    { feature: "Device configuration", access: [true, true, true, true] },
    { feature: "Automations & scripts", access: [true, true, true, true] },
    { feature: "Domain", access: [true, true, true, true] },
    { feature: "Security (basic)", access: [true, true, true, true] },
    { feature: "Alerts & notifications", access: [true, true, true, true] },
    { feature: "Modes", access: [true, true, true, true] },
    { feature: "Dashboard (premium)", access: [true, true, true, true] },
    { feature: "Residents (premium)", access: [true, true, true, true] }
];

const services = [
    { id: 1, name: "Basic Room", price: "$135", description: "Usually its can be Kitchen, Dining room, Living room", additional: "Optional can add curtain, TV and more devices" },
    { id: 2, name: "Basic Bedroom", price: "$180", description: "Room with different logic on day and night", additional: "" },
    { id: 3, name: "Basic Bathroom", price: "$85", description: "Room with turn on light only, turn off manually", additional: "+$50 fan configuration. Optional can be updated to basic room" },
    { id: 4, name: "Speaker Room", price: "$230", description: "Room with an active speaker, will ask if you still here before turn off the light", additional: "" },
    { id: 5, name: "Vents", price: "$170", description: "Vents will actively open and close based on the temperature in the room", additional: "Dashboard can be updated" },
    { id: 6, name: "Service Room", price: "$20", description: "Usually its can be closet, entrance, other small rooms", additional: "" },
    { id: 7, name: "Cameras", price: "TBD", description: "Camera control installation", additional: "Dashboard can be updated" },
    { id: 8, name: "Custom Automation", price: "$25-50", description: "Can be added custom automation as addition", additional: "" },
    { id: 9, name: "Customize Functionality", price: "TBD", description: "Can be added custom functionality as addition", additional: "Vehicle custom integration, pet feeder, coffee machine" },
    { id: 10, name: "NFC", price: "$1", description: "NFC tag can be added with custom automation", additional: "" }
];

const ServicesPage = () => {
    return (
        <div className="services-page">
            <Header titleName="Smart Home Services" />

            <section className="services-intro-page">
                <div className="intro-content">
                    <h2>Basic Services Included in All Options:</h2>
                    <table className="service-table">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            {tiers.map((tier, index) => (
                                <th key={index}>{tier}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {features.map((item, index) => (
                            <tr key={index}>
                                <td>{item.feature}</td>
                                {item.access.map((hasAccess, tierIndex) => (
                                    <td key={tierIndex}>{hasAccess ? "✅" : "❌"}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="services-list">
                <div className="services-intro-page">
                    <h2>What We Offer</h2>
                    <p>Explore our range of smart home services designed to fit your needs.</p>
                </div>
                <div className="services-table-container">
                    <table className="services-table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Additional</th>
                        </tr>
                        </thead>
                        <tbody>
                        {services.map(service => (
                            <tr key={service.id}>
                                <td>{service.name}</td>
                                <td>{service.price}</td>
                                <td>{service.description}</td>
                                <td>{service.additional}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <BackHomeBtn />
            <Footer />
        </div>
    );
};

export default ServicesPage;
