import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";
import {YupUserSettings} from "./yupUserSettings/YupUserSettings";
import "./AccountSettings.css";
import Cookies from "universal-cookie";

const AccountSettings = () => {
    const user = useSelector((state) => state.user.user);
    const [errorResponse, setErrorResponse] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookies = new Cookies();

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(YupUserSettings),
        defaultValues: {
            phone: user?.phone || "",
            fullUserName: user?.fullUserName || "",
            email: user?.email || "",
        },
    });
    const onSubmit = async (data) => {
        const usat = await document.cookie
            .split('; ')
            .find(row => row.startsWith('usat='))
            ?.split('=')[1];
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_SERVER_DOMAIN}/update`,
                {
                    userId: user.id,
                    password: data.password,
                    phone: data.phone,
                    fullUserName: data.fullUserName,
                    userEmail: data.email,
                },
                {
                    headers:
                        {
                            Authorization: `${usat}`, // Use the token in the Authorization header
                        },
                    withCredentials: true
                },
                {timeout: 5000});

            // await cookies.set("usat",res.data.message);

            if (res.status === 200) {
                console.log("updated");
                setErrorResponse(null);
                alert("Changes saved successfully!");
                await cookies.remove("usat");
                // await cookies.set("usat", res.data.token);
                await cookies.set('usat', res.data.token, {
                    path: '/',                // Cookie is available to the entire domain
                    secure: true,             // Only send the cookie over HTTPS
                    maxAge: 60 * 60 * 24 * 30,  // 30 days
                });
                // navigate("/login"); // Redirect to the profile page

            } else {
                console.log("Unexpected success status:", res.status);
                navigate("/login"); // Redirect to the profile page
            }

        } catch (e) {
            if (e.response) {
                // Server responded with a status outside the 2xx range
                const {status} = e.response;

                if (status === 401) {
                    console.log("Authorization error");
                } else if (status === 404) {
                    console.log("User not found");
                } else if (status === 500) {
                    console.log("Failed to create subscription");
                } else {
                    console.log("Unhandled error:", status);
                }
            } else {
                // Network or unexpected error
                console.error("Unexpected error:", e.message || e);
            }
        }


    };

    return (
        <div className="account-settings">
            <form onSubmit={handleSubmit(onSubmit)} className="settings-form">
                <h3 className="form-title">Account Settings</h3>
                <div className="form-grid">
                    <div className="form-column">
                        <div className="input-group">
                            <label htmlFor="name">Your Full Name</label>
                            <TextField
                                {...register("fullUserName")}
                                name="fullUserName"
                                variant="outlined"
                                placeholder={user?.fullUserName || "Not set"}
                                error={!!errors.fullUserName}
                                helperText={errors.fullUserName?.message}
                                fullWidth
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Your Email</label>
                            <TextField
                                {...register("email")}
                                name="email"
                                variant="outlined"
                                placeholder={user?.email || "Not set"}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="input-group">
                            <label htmlFor="phone">Your Phone</label>
                            <TextField
                                {...register("phone")}
                                name="phone"
                                variant="outlined"
                                placeholder={user?.phone || "Not set"}
                                error={!!errors.phone}
                                helperText={errors.phone?.message}
                                fullWidth
                            />
                        </div>
                        <div className="input-group">
                            <label htmlFor="password">Confirm Your Password</label>
                            <TextField
                                {...register("password")}
                                type="password"
                                variant="outlined"
                                placeholder="Enter your current password"
                                error={!!errors.password}
                                helperText={errors.password?.message}
                                fullWidth
                            />
                        </div>
                    </div>
                </div>
                {errorResponse && <span className="error-message">{errorResponse}</span>}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className="save-btn"
                    fullWidth
                >
                    Save Changes
                </Button>
            </form>
        </div>
    );
};

export default AccountSettings;
