import { configureStore } from "@reduxjs/toolkit";
import GetUserSlice from "./slices/GetUserSlice";
import AccountChangesAlertSlice from "./slices/AccountChangeAlertSlice";

const store = configureStore({
  reducer: {
    user: GetUserSlice,
    AccountChangesAlert: AccountChangesAlertSlice,
  },
});

export default store;
