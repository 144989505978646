import React, {useEffect, useState} from "react";
import "./AdminPage.css";
import "../../../pageComponents/headerFooter/HeaderSection.css";
import Header from "../../../pageComponents/headerFooter/Header";
import Footer from "../../../pageComponents/headerFooter/Footer";
import {useNavigate} from "react-router-dom";
import Loader from "../../../loader/Loader";
import axios from "axios";
import Cookies from "universal-cookie";
import {useDispatch, useSelector} from "react-redux";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";

const ITEMS_PER_PAGE = 10;

const AdminPage = () => {
    const [loading, setLoading] = useState(true);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    axios.defaults.withCredentials = true;

    useEffect(() => {
        const usatCookie = cookies.get("usat");
        if (!usatCookie && user?.sbStatus !== "admin") {
            navigate(`/login`);
        }else
        {
            setLoading(false);
        }
    }, [cookies, navigate, user?.sbStatus]);



    return loading ? (
        <Loader/>
    ) : (
        <div className="admin-page">
            <Header titleName="Admin Page"/>
            <section className="admin-content">

                <div className="admin-menu">
                    <button
                        // onClick={handleSettings}
                        className="admin-menu-btn admin-btn"
                    >
                        Settings
                    </button>
                    {user ? (
                        user.sbStatus === "admin" ? (
                            <button
                                onClick={() => {
                                    navigate(`/cabinet/${user.username}/subscriptions`);
                                }}
                                className="admin-menu-btn admin-btn"
                            >
                                View subscriptions
                            </button>
                        ) : (
                            <button
                                className="admin-menu-btn admin-btn"
                            >
                                Thank you
                            </button>
                        )
                    ) : (
                        <a href="/login">Login</a>
                    )}
                    <button
                        // onClick={handleBillings}
                        className="admin-menu-btn admin-btn"
                    >
                        Billing
                    </button>

                </div>

                <button
                    className="back-cabinet-btn"
                    onClick={() => navigate(`/cabinet/${user.username}/home`)}
                >
                    Back to Cabinet
                </button>
            </section>
            <Footer/>
        </div>
    );
};

export default AdminPage;
