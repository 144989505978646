import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../homePage/HomePage"; // Adjust the path according to where your HomePage.jsx is located
import NotFound from "../notFoundPage/NotFoundPage";
import ServicesPage from "../servicesPage/ServicesPage";
import PrivacyPolicy from "../privacyPolicy/PrivacyPolicy";
import TermsOfService from "../termsOfService/TermsOfService";
import FAQPage from "../faqPage/FAQPage";
import LicensePage from "../licensePage/LicensePage";
import LoginPage from "../homePage/loginPage/LoginPage";
import UserCabinet from "../homePage/cabinet/UserCabinet";
import UserSettings from "../homePage/cabinet/settingsPage/UserSettings";
import AccountSettings from "../homePage/cabinet/settingsPage/AccountSettings";
import PasswordSettings from "../homePage/cabinet/settingsPage/PasswordSettings";
import RecoveryPasswordToken from "../homePage/cabinet/settingsPage/recoveryPassword/RecoveryPasswordToken";
import UserBillings from "../homePage/cabinet/billingsPage/UserBillings";
import ForgotPassword from "../homePage/cabinet/settingsPage/recoveryPassword/ForgotPassword";
import SubscriptionsPage from "../homePage/cabinet/adminPage/SubscriptionsPage";
import PaymentSuccess from "../paypal/payment-success/PaymentSuccess";
import PaymentCancel from "../paypal/payment-cancel/PaymentCancel";
import SupportPage from "../supportPage/SupportPage";
import AdminPage from "../homePage/cabinet/adminPage/AdminPage";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/payment-success" element={<PaymentSuccess />} />
            <Route path="/payment-cancel" element={<PaymentCancel />} />
            <Route exact path="/services/" element={<ServicesPage/>} />
            <Route exact path="/support/" element={<SupportPage/>} />
            <Route exact path="/privacy-policy/" element={<PrivacyPolicy/>} />
            <Route exact path="/terms-of-service/" element={<TermsOfService/>} />
            <Route exact path="/faq/" element={<FAQPage/>} />
            <Route exact path="/license/" element={<LicensePage/>} />
            <Route exact path="/login/" element={<LoginPage/>} />
            <Route exact path="cabinet/:username/home" element={<UserCabinet />} />
            <Route exact path="recovery-password" element={<RecoveryPasswordToken />} />
            <Route exact path="forgot-password" element={<ForgotPassword />} />
            <Route exact path="cabinet/:username/admin-page" element={<AdminPage />} />
            <Route exact path="cabinet/:username/billings" element={<UserBillings />} />
            <Route exact path="cabinet/:username/subscriptions" element={<SubscriptionsPage />} />
            {/*<Route exact path="cabinet/:username/settings" element={<UserSettings />} />*/}
            <Route path="cabinet/:username/settings" element={<UserSettings />}>
                <Route path="account-settings" element={<AccountSettings/>} />
                <Route path="password-settings" element={<PasswordSettings />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
