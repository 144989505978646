import React, {useEffect, useState} from "react";
import "./UserSettings.css";
import "../../../pageComponents/headerFooter/HeaderSection.css";
import Header from "../../../pageComponents/headerFooter/Header";
import Footer from "../../../pageComponents/headerFooter/Footer";
import {useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../loader/Loader";
import AccountSettings from "./AccountSettings"; // Import the AccountSettings component
import PasswordSettings from "./PasswordSettings"; // Import the PasswordSettings component
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "universal-cookie";
import BackHomeBtn from "../../../pageComponents/backHomeBtn/backHomeBtn";
const UserSettings = () => {

    const [error, setError] = useState(null);
    const [isLogin, setIsLogin] = useState(null);
    const [isLoginTransit, setLoginTransit] = useState(null);
    const { hash } = useLocation();
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    axios.defaults.withCredentials = true;

    const [activeTab, setActiveTab] = useState("account-settings"); // Default to account settings
    const handleTabChange = (tab) => {
        setActiveTab(tab); // Change active tab
        navigate(`#${tab}`); // Update URL hash for smooth scrolling
    };

    useEffect(() => {
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [hash]);

    useEffect(() => {
        try {
            // if (isLogin) {
            const usatCookie = cookies.get("usat");
            if(!usatCookie){
                navigate(`/login`);
            }
            // }
        } catch (e) {
            console.error(e);
        }
    },);

    const [email, setEmail] = useState("");  // Email state
    const [password, setPassword] = useState("");  // Password state
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);  // Unsubscribe state
    const [loading, setLoading] = useState(false);  // Loading state

    // Function to handle email change
    const handleEmailChange = (e) => setEmail(e.target.value);

    // Function to handle password change
    const handlePasswordChange = (e) => setPassword(e.target.value);

    // Handle Unsubscribe button
    const handleUnsubscribe = () => {
        // Add your unsubscribe logic here
        setIsUnsubscribed(true);
    };

    // Handle form submission (this could be tied to a backend API for real functionality)
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        // Simulate an API request
        setTimeout(() => {
            setLoading(false);
            alert("Settings updated successfully!");
        }, 2000); // Simulate a delay
    };


    return !isLoginTransit ? (
        <div className="settings-page">
            <Header titleName="User Settings" />
            <section className="settings-content">
                <div className="settings-navigation">
                    <button
                        onClick={() => handleTabChange("account-settings")}
                        className={`settings-btn ${activeTab === "account-settings" ? "active" : ""}`}
                    >
                        Account Settings
                    </button>
                    <button
                        onClick={() => handleTabChange("password-settings")}
                        className={`settings-btn ${activeTab === "password-settings" ? "active" : ""}`}
                    >
                        Password Settings
                    </button>
                </div>
                <div className="settings-details">
                    {activeTab === "account-settings" && <AccountSettings />}
                    {activeTab === "password-settings" && <PasswordSettings />}
                </div>
                <button
                    className="back-cabinet-btn"
                    onClick={() => navigate(`/cabinet/${user?.username}/home`)}
                >
                    Back to Cabinet
                </button>
            </section>
            {/*<BackHomeBtn />*/}
            <Footer />
        </div>
    ): (
        <Loader />
    );;
};

export default UserSettings;
