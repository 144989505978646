import * as yup from "yup";

export const YupLogin = yup.object({
  username: yup
    .string()
    .required("This field cannot be empty")
    .matches(/^\S+$/, "The password must not contain spaces"),
  password: yup
    .string()
    .required("This field cannot be empty")
    .matches(/^\S+$/, "The password must not contain spaces"),
});
