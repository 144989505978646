import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        description: '',
        purpose: '',
        package: '',
        howHeard: '',
        anyPet: '',
        zipcode: '',
        titleName: ''
    });
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        let errors = {};

        // Email validation (basic regex)
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            errors.email = 'Please enter a valid email address.';
        }

        // Phone validation (simple US-based phone format)
        const phonePattern = /^[0-9]{10}$/;
        if (!phonePattern.test(formData.phone)) {
            errors.phone = 'Please enter a valid 10-digit phone number. ( without any extra character like "+" )';
        }

        return errors;
    };

    const handleSubmit = (e) => {
        formData.titleName = formData.name + ' ' + formData.zipcode
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length === 0) {
            // If no errors, send the email

            emailjs.send(`${process.env.REACT_APP_EMAILJS_SERVICE}`, `${process.env.REACT_APP_EMAILJS_CONTACT_FORM_TEMPLATE}`, formData, `${process.env.REACT_APP_EMAILJS_USER_ID}`)
                .then((response) => {
                    setFormSubmitted(true); // Hide the form and show the thank-you message
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('Failed to send message');
                });
        } else {
            setFormErrors(errors); // Display validation errors
        }
    };

    return (
        <section className="contact-info">
            <h2>Contact Us</h2>
            <p id="contact">Our team is here to assist you with any inquiries you may have.</p>
            {formSubmitted ? (
                <div className="thank-you-message">
                    <h3>Thank you! Your message has been sent.</h3>
                </div>
            ) : (
                <form className="contact-form" onSubmit={handleSubmit}>
                    <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="phone"
                        placeholder="Your Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                    />
                    {formErrors.phone && <p className="error">{formErrors.phone}</p>}

                    <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {formErrors.email && <p className="error">{formErrors.email}</p>}

                    <textarea
                        style={{ resize: "none" }}
                        name="description"
                        placeholder="Your Description"
                        value={formData.description}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <select className="select-option"
                            name="purpose"
                            value={formData.purpose}
                            onChange={handleChange}
                            required
                    >
                        <option value="">Select Purpose</option>
                        <option value="home">Home</option>
                        <option value="apartment">Apartment</option>
                        <option value="other">Other</option>
                    </select>
                    <select className="select-option"
                            name="package"
                            value={formData.package}
                            onChange={handleChange}
                            required
                    >
                        <option value="">Select Package</option>
                        <option value="Studio">Studio</option>
                        <option value="1-2-bedroom">1-2 Bedroom</option>
                        <option value="3-5-bedroom">3-5 Bedroom</option>
                        <option value="5+ Bedroom">5+ Bedroom</option>
                    </select>
                    <select className="select-option"
                            name="howHeard"
                            value={formData.howHeard}
                            onChange={handleChange}
                            required
                    >
                        <option value="">How Did You Hear About Us?</option>
                        <option value="friend">Friend</option>
                        <option value="google">Google</option>
                        <option value="facebook">Facebook</option>
                        <option value="youtube">YouTube</option>
                        <option value="instagram">Instagram</option>
                        <option value="Other">Other</option>
                    </select>
                    <select className="select-option"
                            name="anyPet"
                            value={formData.anyPet}
                            onChange={handleChange}
                            required
                    >
                        <option value="">Do you have any pets?</option>
                        <option value="No pets">No pets</option>
                        <option value="Small">Small</option>
                        <option value="Medium">Medium</option>
                        <option value="Large">Large</option>
                    </select>
                    <input
                        type="text"
                        name="zipcode"
                        placeholder="Your Zip code"
                        value={formData.zipcode}
                        onChange={handleChange}
                        required
                    />
                    <button type="submit" className="cta-button">Submit</button>
                </form>
            )}
        </section>
    );
};

export default ContactForm;
