import React from "react";
import "./Loader.css";
const Loader = () => {
  return (
      <div className="loaderCentFilter">
          <svg width="1500" height="1500" className="svgLoader">
              <g className="puzzle_loaderWrap" transform="translate(100,100) scale(0.33) ">
                  <g className="puzzle">
                      <g className="loaderWrap blue_loaderWrapOne">
                          <path d="M318.5,365.3c-2.3,2.1-2.1,5.3-2.9,8c-3.6,12.6-17.4,20.7-30.4,15.4c-4.1-1.7-8.5-2.7-12.5-4.7c-4.1-2-8.3-1.8-12.6-1.6
			c-3.5,0.1-6.5,5.2-6.8,11.2c-0.5,11.6,1.2,22.9,2.6,34.4c1.5,12.5,4.2,25,3.4,37.8c-0.1,1.7,5.8,3.1,4.1,3.8
			c-3.5,0.1-9.8,0.3-11.6,0.3c-44.2,0-88.4,0-132.6,0c-14.3,0-43.8,0.2-56.4-0.3c-19.7,0.8-22.4-17.4-22.8-31.2
			c0.2-7.3,0.5-13.5,0.3-20.2c0-52.8-0.6-153.7,0-164c0.1-3.9,4.1-3.6,6.2-3.7c15.2-0.7,30-3.8,45.1-5.3c3.7-0.4,7.5-0.7,11.2-0.9
			c4.4-0.6,8.8-0.6,13.2,0c3.9,0.3,7.8,1,11.1,2.9c6.5,3.7,7.6,11.3,3.9,20.8c-2.1,5.5-4.4,11-6.3,16.7
			c-4.3,13.2,6.9,22.2,17.1,22.8c3.7,0.7,7.4,0.6,11.1,0c6-1.7,11.8-3.4,16.1-8.6c4.9-6,3.8-12.2,2.2-18.8
			c-1.3-5.4-3.6-10.6-4.4-16.2c-1.8-11.6,3.6-18.7,15.4-19.5c0.8-0.1,1.7-0.2,2.5-0.2c4.1-0.4,8.2-0.6,12.3,0.1
			c12.3,1.3,24.6,3,36.8,4.9c7.1,1.1,14.3,1.4,21.5,2.1c2.8,0.3,3.6,1.4,3.7,4.1c0.4,7.9-1.4,15.7-2.2,23.5
			c-1.1,11.2-3.9,22.2-4,33.6c-0.5,4.4-0.6,8.7,0,13.1c0.1,1.5,0,3,0.3,4.5c1.8,7.7,7.1,10.4,14.9,8c6.8-2.1,13.5-4.1,20.4-5.8
			c9.9-2.5,22.1,4.4,25.7,13.8c1.5,3.8,1.4,8.1,4,11.6C318.5,359.9,318.5,362.6,318.5,365.3z"/>
                          <path className="st2" d="M199.3,249c10.2,1.4,20.4,2.8,30.6,4.1c6.8,0.9,13.6,2.1,20.5,2.1c5.5,0,4.2,4.6,3.8,7.7
			c-1.4,12-3.1,23.9-4.8,35.8c-1,7.1-2.1,14.3-2.1,21.5c0,4.3,0.8,8.4,2.1,12.4c2.9,9.3,9.4,12.5,19.1,10.2
			c6.6-1.6,12.8-4.1,19.4-5.7c11-2.7,18.8,2.8,22.3,11.3c3.8,9.3,3.4,18.6-1,27.4c-4.4,8.7-13.8,11.9-25.8,7.1
			c-6.4-2.6-12.7-5.8-20-5.6c-8.3,0.2-12.4,3.5-14,11.8c-0.2,1.1-0.6,2.2-0.9,3.2c0.5,5.7,0.5,11.4,0,17.1c1.6,18,5.7,35.8,6,53.9
			c-0.9,3.1-7.4,1.9-9.1,1.9c-27.5,0.1-51-0.3-78.6-0.3c-16.5,0-33,0-49.6,0c-14,0-43.4-0.4-54.3-0.3c-11.4,0.6-16.3-7.2-17.2-18.2
			c-0.6-5.3-0.3-36.2-0.3-49.2c0-45.5,0-91,0-136.5c0-1.8-0.7-3.7,0.7-5.3c14.3-0.6,28.5-3,42.7-5c4.4-0.6,8.8-0.9,13.2-1.3
			c5.1-0.5,10.1-0.5,15.2,0c1.9,0.4,3.8,0.6,5.7,1.2c4.8,1.5,6.8,6.9,4.7,13.1c-2,5.7-4.2,11.4-6.4,17c-6.2,15.4,2,27.2,15.9,31.3
			c11.8,3.4,22.9,1.5,32.7-6.3c8.9-7.1,9.9-16.1,6.8-26.3c-1.5-4.9-3-9.9-4.2-14.9c-2.2-9.5,0.7-13.4,10.4-14.9
			c0.5-0.1,1-0.1,1.5-0.2C189.2,248.5,194.3,248.5,199.3,249z"/>
                      </g>
                      <g className="loaderWrap yellow_loaderWrapTwo">
                          <path d="M184.4,252.7c-3.2,0.5-6.4,0.6-9.2,2.8c-5.8,4.6-3.6,10.2-2.1,15.7c1.4,5.6,3.6,11,4.5,16.8c2.1,12.5-5.4,21.6-14.5,25.1
			c-3.9,1.5-7.8,2.7-11.8,4c-2.7,0-5.3,0-8,0c-2.7-2-6.1-1.1-9.1-2.5c-13.1-5.9-17.8-16.1-13.2-29.9c1.6-4.7,3.7-9.2,5.4-13.9
			c3.9-11.4,3.4-15.6-9-18.2c-5.1-0.5-10.1-0.6-15.2,0c-10,1.1-20.1,2.9-30.1,3.9c-8.8,0.8-17.5,3.3-26.5,2.4
			c-2.2-0.2-4.5,0.1-4.6-3c-0.9-1.7-0.4-3.6-0.4-5.4c0-52.6-0.1-172-0.4-178.4c-0.2-16.6,2.6-31.9,21.9-32.2c6.1,0,9-0.1,13.4-0.3
			c58.5,0,116.9,0,175.4,0c2,0,4-0.2,6,0.2c3,2.3,3.8,5.3,3.9,9c0.2,10.9,2.1,21.7,3.8,32.5c1.1,7.3,1.6,14.6,2.3,21.9
			c0.7,3.4,0.1,6.9,0.3,10.4c-0.1,2.7-0.4,5.3-0.6,8c-0.8,8.4-6.9,13.8-15.4,14.2c-4.3,0.2-8.3-0.8-12.2-2.4
			c-4.3-1.7-8.7-3.4-13.3-4.5c-10.9-2.9-18.3,1.4-21.4,12.3c-0.3,1.1-0.5,2.3-0.8,3.4c-1,3.6-0.9,7.3,0,10.9
			c0.4,1.6,0.5,3.3,1.1,4.9c3.8,10.9,10.7,14.7,22.3,12.3c6.9-1.5,13.2-4.8,20.2-6c10.4-1.8,18.4,3.9,19.7,14.1
			c0.2,1.8,0.7,3.6,0.5,5.5c0.3,4.8-0.6,9.7,0.5,14.5c0,0.7,0,1.3,0,2c-3.8,4.2-2,9.7-2.7,14.5c-1.7,12-4,23.9-4.4,36
			c-0.1,3.4-2.2,5.8-6.5,5.8c-9.4,0-18.6-1.4-27.8-2.9c-9.5-1.5-19.1-2.3-28.7-3.3C193.2,252.1,188.8,252.1,184.4,252.7z"/>
                          <path className="st3" d="M255.6,45.2c0.8,14.7,2.9,29.2,5,43.7c0.7,4.7,0.9,9.6,1.3,14.3c0.4,3.7,0.5,7.4,0,11.1
			c-0.1,2.3-0.9,4.5-1.3,6.8c-1.3,7.4-7.1,10.2-15.1,7.7c-5.6-1.8-11-3.9-16.6-5.7c-14.8-4.7-25.8,1-30.5,16.1
			c-3.3,10.6-2.2,20.8,3.8,30.2c6.2,9.8,15.5,13,26.7,9.7c6.1-1.8,11.8-4.6,18.2-5.5c9.7-1.4,11-0.6,14,8.7c0.3,1.1,0.6,2.2,0.9,3.3
			c0.5,5.4,0.5,10.8,0,16.2c-1.7,12.2-3.4,24.4-5,36.7c-0.6,4.3-0.9,8.6-1.1,12.9c-0.1,1.7-0.2,2.9-2.4,2.7
			c-6.4-0.5-12.9-0.5-19.2-1.4c-9.5-1.3-19-3.1-28.7-3.7c-2.9-0.2-5.8-0.8-8.8-1.2c-3.5-0.5-6.9-0.4-10.4,0
			c-8.3,0.3-15.7,2.3-19.1,11.1c-2.3,5.9,1,11.1,2,16.6c0.8,4.3,2.8,8.2,3.4,12.6c1.4,10.3-2.5,17.7-12.2,21.2
			c-2.3,0.8-4.7,1.4-7,2.1c-3.4,0.7-6.7,0.5-10.1,0.1c-4.9,0.3-9-2-12.7-4.6c-6-4.3-7.8-10.9-5.4-18.4c2.3-7.1,5-14.1,7.3-21.2
			c2.1-6.3,0.7-12.6-3.7-15.7c-3.6-2.6-8.2-3-12.5-3.8c-4.1-0.5-8.3-0.5-12.4,0c-13,1.3-25.9,2.9-38.8,5.1c-6,1-12.1,0.9-18.2,1.3
			c-2.8-0.7-2-3-2.1-4.5c-0.1-23.4,0.5-46.9,0.5-70.3c0-27.6,0-55.2,0-82.8c0.2-8.9-0.3-17.8,0.1-26.6c0.1-2.4,0.1-4.7,0.1-7.1
			c-0.1-3.9,0.1-7.8,3.2-11.2c3.6-4,5.4-6.4,13.2-6.7c6.8-0.2,9.7-0.5,16.4-0.3c57.3,0,114.5,0,171.8,0
			C252,44.6,253.9,44,255.6,45.2z"/>
                      </g>
                      <g className="loaderWrap green_loaderWrapThree">
                          <path d="M367.5,192.1c7.8,1.3,14.8,4,19.7,10.5c5.2,7,5,14.8,2.5,22.8c-1.7,5.5-3.8,10.9-4.9,16.7c-1.2,5.8,0.9,10.1,6.5,12.2
			c7.1,2.6,14.3,2.3,21.7,1.2c8.2-1.2,16.5-2,24.7-3c9.6-1.2,19.2-2.9,29-2.3c2.3,0.1,4.2,0.6,5.6,2.4c1.1,1.7,0.6,3.6,0.6,5.3
			c0,44.5,0,88.9,0,133.4c0,11.3,0,22.6,0,33.9c0.3,6.2,0.5,12,0.5,18.5v3.5c0,3.4-0.8,6.8-2.5,9.7c-1.2,2-2.7,3.9-4.6,5.6
			c-4.1,3.7-8.5,5.8-13.9,6.4c-3.4,0.7-6.9,0.2-10.3,0.3c-10.6,0-21.3,0-31.9,0c-49.2,0-148.9,0.6-153.5,0.4
			c-3.8-1.4-3.5-5.4-3.5-8.7c-0.3-14.2-2.4-28.2-4.9-42.1c-0.6-3.6-1.6-21.2-0.9-27.3c1.5-4.8,2.1-9.8,6.5-13.2
			c2.9-2.3,6.1-3.4,9.5-2.5c6,1.5,11.9,3,17.5,5.7c3.2,1.6,6.8,1.9,10.4,2.2c9,0.7,14.6-3.2,17.7-11.4c3.7-9.8,3.1-19.5-2.1-28.5
			c-4.7-8.1-13.4-10.1-23.6-6.9c-5.2,1.6-10.3,3.4-15.6,4.9c-11.5,3.2-18.5-2.7-20.4-14.3c-2.3-13.9,0.7-27.3,2.7-40.9
			c1.4-9.4,2.4-18.8,3.2-28.3c0.5-7,1.2-8.1,8-7.9c11.3,0.2,22.5,2.1,33.6,3.7c7.1,1.1,14.3,1.4,21.5,2.1c3.7-0.5,7.4-0.5,11.1,0
			c1.3-0.7,2.8-0.6,4.2-0.8c9.4-1.4,11.2-5.9,9.3-14c-1.9-7.9-6.1-15-7.8-23.1c-1.4-6.8,0.4-12.3,4.2-17.1c4.1-5.3,10-8.5,17.1-9
			C358.7,191.4,363.1,191.4,367.5,192.1z"/>
                          <path className="st4" d="M259.2,463.8c-2.1-5.1-0.9-10.6-1.6-16c-1.4-9.9-2.8-19.8-3.9-29.7c-1.2-10.1-2.6-20.3-0.3-30.5
			c1.1-4.9,6-7.7,10.9-6.7c8.3,1.8,15.9,5.4,24,7.7c9.5,2.7,21.8-3.9,25.4-13.8c3.2-8.8,3.6-17.7,0.9-26.9
			c-4-13.8-16.3-20.4-30.8-16.4c-6.4,1.8-12.2,5.4-19,5.8c-8.4,0.4-11.9-2.9-12.3-11.5c0-0.8-0.2-1.7-0.2-2.5c-0.5-4-0.5-8,0-12.1
			c0.9-16,3.7-31.7,6-47.5c0.3-1.8,0.2-3.6,0.2-5.5c0.5-5,4-3.6,7-3.3c16,2,32,4,47.9,6c6,1.6,11.5,0.3,17.3-0.6
			c4.5-0.3,8.9-1.2,12.2-4.5c5.6-5.7,4-12.4,1.9-18.9c-2.1-6.7-5.4-12.9-6.9-19.8c-1.1-5,1.9-13.7,6.4-16.1
			c11-5.9,22.3-6.4,33.4-0.3c9,5,10.8,11.9,7.3,23.4c-1.7,5.6-2.9,11.4-4.8,16.8c-3.6,9.9,6.4,19.2,14.7,19.2c1.2,0,2.3,0.1,3.5,0.2
			c4.8,0.4,9.6,0.5,14.4,0c14-0.7,27.9-3.2,41.8-4.8c4.3-0.5,8.7-0.2,13-0.2c1.1,1.7,0.5,3.6,0.5,5.4c0,24.2,0,48.4,0,72.6
			c0,29.7,0,59.3,0,89c-0.2,8.4,0.4,16.9-0.3,25.3c-0.3,8.8-7.4,17.1-18.1,16.2c-12.1,0.7-24.1,0.1-36.2,0.3c-49.6,0-99.1,0-148.7,0
			C262.8,464.1,261,464.4,259.2,463.8z"/>
                      </g>
                      <g className="loaderWrap red_loaderWrap">
                          <path d="M398.2,264.2c-5.3-0.1-10.1-1-14.3-4.9c-6.2-5.9-5-12.5-3-19.4c1.5-5.1,3.5-9.9,4.5-15.2c2.3-12.3-4.1-21.4-16.5-23.7
			c-6.9-1.3-13.7-1.6-20.1,1.3c-10.9,4.9-13.8,13.1-9.6,24.4c2.1,5.7,4.8,11.3,6.2,17.3c2.6,11.6-1.3,17.7-12.8,20
			c-1.1,0.2-2.4,0.6-3.5,0.6c-5.7,0-10.6,0-15.7-0.3c-5.4-0.3-9.2-0.2-13.7-1c-10.2-2-20.6-2.9-30.9-4.2c-2.3-0.3-4.6,0-7,0
			c-5.7-0.1-7.8-2.1-7.6-7.7c0.3-10.9,2.1-21.6,3.9-32.4c1-6.2,0.9-12.6,2.3-18.9c0.5-5.4,0.6-10.7,0-16.1c-0.4-1.4-0.8-2.8-1.1-4.2
			c-1.1-5-4.5-7.6-9.6-7.8c-6.6-0.3-12.3,2.6-18.3,4.5c-6.7,2.1-13.4,4.3-20.3,0.9c-9.5-4.7-14.4-12.6-15.1-23.2
			c-0.5-3.5-0.5-6.9,0-10.4c0.4-10.9,8-21.1,17.1-23.3c4.7-1.1,9-0.1,13.3,1.2c5.7,1.7,11.1,4.4,16.8,5.7c8,1.8,11.2,0.7,16.1-10.2
			c0.6-1.3,0.7-2.8,1.1-4.2c0.6-3.7,0.6-7.4,0-11c-1.1-9.5-1.9-19.1-3.3-28.6c-1.4-9.7-3.2-19.4-2.9-29.2c0.1-2.1-1.4-4.5,2.2-4.6
			c19.4,0.1,91.8-0.9,134.7-0.9c12,0,23.9,0,35.9,0c7.4,0,14.7,0,22.1,0c7.9-0.4,14.9,1.3,20.2,8.1c2.7,3.5,3.1,5.5,3.8,11.5
			c0,1,0.2,19.8-0.1,28.3c0,54.4,0,109.2,0.1,163.6c0,1.8,0,5.7,0,6.1c-7.3,4-38.1,5.6-46.3,6.5C416.9,264,402.6,265,398.2,264.2z"
                          />
                          <path className="st2" d="M201.1,143c1.6-12.5,9.7-19.1,19-17.8c5.5,0.8,10.8,2.9,15.9,5.1c3.9,1.6,7.8,2.6,12.1,2.9
			c9.6,0.8,14.5-5.2,16.1-13.5c0.4-2.2,1.1-4.4,1.2-6.7c0.6-3.7,0.6-7.4,0-11c-2-19.3-10.2-56.4-6.1-57.9c3-0.7,93.7-0.6,137.8-0.6
			c11,0,22.1,0,33.1,0c6.4,0.2,12.8-0.4,19.2,0.3c8.4,0,15.3,3,18.2,14.1c0.7,4.1,0.1,8.2,0.3,12.3c0,12.9,0,25.7,0,38.6
			c0,5.5,0,10.9,0,16.4c0,20.5,0,41,0,61.5c0,1.8,0.4,3.7-0.4,5.4c0,1.9,0,3.8,0,5.7c0.8,13.4,0.3,26.8,0.3,40.2
			c0,4.9,0.7,9.9-0.5,14.8c-13.3,1.4-26.6,2.6-39.7,4.9c-5,0.9-10.1,1-15.2,1.4c-5,0.5-10.1,0.5-15.1,0c-9.4,0.2-16.1-5.9-11.4-18.4
			c1.8-4.9,3.6-9.8,4.4-14.9c2.6-15.7-3-24.1-18.6-29c-10.3-3.2-20.2-2.6-29.4,3.2c-7.3,4.6-11.5,13.9-9.2,23.2
			c1.9,7.4,5.4,14.1,7.4,21.4c2.4,9,0.3,13-11.1,14.5c-5.1,1.4-9.1,1.2-14.2,0.7c-12.1-1.5-24.2-3-36.2-4.6
			c-5.8-0.3-10.1-0.9-16.7-1.2c-2.4-0.1-3.1-0.6-3-3c0.2-8,1.6-15.9,2.9-23.7c1-5.8,1.5-11.6,2.2-17.4c0.5-4.2,0.8-8.5,1.1-12.7
			c0.7-3.7,0.7-7.5,0-11.2c-1-4.5-0.8-9.3-4-13.3c-6.4-8.1-14.4-6.1-22.4-3.9c-4.6,1.3-9.1,3.2-13.8,4.2c-13.2,3-22.8-4.3-24.2-17.9
			C200.5,151,200.4,147,201.1,143z"/>
                      </g>
                  </g>
              </g>
          </svg>
      </div>
  )
};
export default Loader;
